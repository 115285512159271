import React from 'react';
import { IoAlbumsOutline, IoNotifications, IoSearch } from 'react-icons/io5';
import './index.css';
import { useAuth } from '../../../context/AuthContext';
import { Dropdown } from 'react-bootstrap';

function Header() {

  const { user, logout } = useAuth();

  const [showDropdown, setShowDropdown] = React.useState(false);

  return (
    <header>
      <div className='container-fluid'>
        <div className='header-wrapper'>
          <div className='header-left'>

          </div>
          <div className='header-right'>
            <div className='notification-icon'>
              <a href="#">
                <IoNotifications size={20} />
              </a>
            </div>
            <div className='widget-icon'>
              <a href="#">
                <IoAlbumsOutline size={20} />
              </a>
            </div>
            <div className='user-head-info'>
              <Dropdown>
                <Dropdown.Toggle variant="dark">
                  <div className='user-img'>
                    <img src={user && user.photoURL} className='img-fluid' alt='...' />
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="pb-0 border-0 shadow">
                  <div className='user-info-dropdown shadow'>
                    <div className='user-info'>
                      <div className='user-drop-img'>
                        <img src={user && user.photoURL} className='img-fluid' alt='...' />
                      </div>
                      <h6 className='mb-0 text-center'>{user && user.displayName}</h6>
                      <p className='text-center'>{user && user.email}</p>
                    </div>
                    <div className='user-drop-menu'>
                      <button onClick={logout}>Logout</button>
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
