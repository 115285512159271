import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useAuth } from './../../../context/AuthContext';
import { toast } from 'react-toastify';
import './index.css';

const Login = () => {

  const { login } = useAuth();

  async function handleGoogleLogin() {
    try {
      const res = await login();
      console.log('Login Response : ', res);
    } catch (error) {
      if (error.code === 'auth/popup-closed-by-user') {
        toast.error(error.code, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  }

  return (
    <div className='login-wrapper d-flex align-item-center'>
      <div className='container'>
        <div className='row align-items-center justify-content-around'>
          <div className='col-md-6 col-lg-5 col-12 py-5 my-5'>
            <div className='card border-0 pt-3 mb-0 bg-white-cc shadow'>
              <div className='p-3'>
                <h2 className="text-center fw-bold mt-3">Political Banner Maker</h2>
                <h5 className="text-center fw-light text-uppercase mt-3"><small>Administrator</small></h5>
                <h4 className="text-center fw-bold text-uppercase">Login</h4>
              </div>
              <div className='p-md-4 m-md-1 p-3 pb-5'>
                <div className='d-flex align-item-center justify-content-center'>
                  <Button variant="primary" className='w-75' onClick={handleGoogleLogin}>
                    Sign In with Google
                  </Button>
                </div>
              </div>
              <hr className='border-info m-0' />
              <div className='p-4'>
                <div className='text-center'>
                  <p className='mb-0'>Powered By <a href="https://www.next2technologies.com/" className='text-dark fw-bold' target='_blank'>Next2Technologies</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </div >
  )
}

export default Login;