import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCx8mmATqIx4_Nx3qyQqmjNpbmLrrdhhOc",
  authDomain: "politicalbannermaker.firebaseapp.com",
  projectId: "politicalbannermaker",
  storageBucket: "politicalbannermaker.appspot.com",
  messagingSenderId: "274838563803",
  appId: "1:274838563803:web:68e709439fd9ffdda255fe",
  measurementId: "G-Z4G47SMBZM"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export default app;