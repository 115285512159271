import React, { useState, useEffect } from 'react';
import './index.css';
import { IoAdd } from 'react-icons/io5';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Leaders from '../../../components/leaders';
import ItemForm from '../../../components/forms/leaders';

function LeaderItems() {

  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState(null);

  function closeDataModal() {
    setData(null);
    setShowModal(false);
  }

  function handleDataResponse(success, error) {
    if (success) {
      closeDataModal();
      toast.success('Successfully Done.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      closeDataModal();
      toast.error('Something went wrong!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }

  function updateData(dataItem) {
    if (dataItem) {
      setData(dataItem);
      setShowModal(true);
    }
  }

  return (
    <section className='section p-3'>
      <div className='row'>
        <div className='col-12'>
          <div className='card custom-shadow bg-white p-3 border-0'>
            <div className='d-flex align-items-center justify-content-between'>
              <h4 className='mb-0'>
                Leaders
              </h4>
              <div>
                <Button
                  onClick={() => setShowModal(!showModal)}
                >
                  <IoAdd size={24} />
                  <span className='ms-2'>New Leader</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col-12'>
          <div className='card custom-shadow bg-white p-3 ps-4 border-0'>
            <Leaders updateData={updateData} />
          </div>
        </div>
      </div>

      {/* NEW DATA MODAL */}

      <Modal
        size="md"
        backdrop="static"
        keyboard={false}
        show={showModal}
        onHide={() => closeDataModal()}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {
              data && data ?
                'Update Leader'
                :
                'Add New Leader'
            }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ItemForm
            updateData={data}
            handleResponse={handleDataResponse}
          />
        </Modal.Body>
      </Modal>

    </section>
  );
}

export default LeaderItems;
