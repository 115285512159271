import { createContext, useContext, useEffect, useState } from 'react';
import {
  onAuthStateChanged,
  signInWithPopup,
  GoogleAuthProvider,
  signOut
} from 'firebase/auth';
import { auth } from './../firebase';
import { Image } from 'react-bootstrap';

const AuthContext = createContext();

const googleProvider = new GoogleAuthProvider();

export const AuthContextProvider = ({ children }) => {

  const [user, setUser] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const dbAuth = localStorage.getItem('authExpress');
        if (dbAuth) {
          setPermissions(JSON.parse(dbAuth));
          setUser(user);
        }

        setUser(user);
      } else {
        setPermissions(null);
        setUser(null)
      }

      setIsLoading(false);
    })

    return () => unsubscribe()
  }, [])

  async function login() {
    return signInWithPopup(auth, googleProvider);
  }

  async function logout() {
    setUser(null);
    await signOut(auth);
  }

  const context = { user, permissions, login, logout };

  return (
    <AuthContext.Provider value={context}>
      {
        isLoading ?
          <div className='position-absolute start-0 end-0 top-0 bottom-0 d-flex align-items-center justify-content-center'>
            <div className='text-center'>
              <Image src='../../images/logo/pbm.png' width={80} className='img-fluid rounded-circle shadow' />
              <h6 className='fw-bold text-success mt-4'>LOADING...</h6>
            </div>
          </div>
          :
          children
      }
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext);