import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, Image, Modal, ProgressBar } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { ClipLoader } from 'react-spinners';
import { doc, setDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytesResumable, deleteObject } from "firebase/storage";
import { db, storage } from '../../../firebase';
import './index.css';
import placeholderImage from '../../../assets/placeholder/placeholder-image.png';
import { IoTrashOutline } from "react-icons/io5";

const templateDimensions = [
  { name: 'posts', width: '1080', height: '1080' },
  { name: 'stories', width: '1080', height: '1920' },
  { name: 'banners', width: '1920', height: '1080' },
  { name: 'wallpapers', width: '1440', height: '2560' }
];

function FestiveForm({ updateData, handleResponse }) {

  const imgRef = useRef();

  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [temID, setTemID] = useState('');
  const [data, setData] = useState({
    templateUrl: '',
    templateStyles: {
      width: '',
      height: ''
    },
    category: '',
    isActive: true,
    type: 'freebie',
    price: 0,
    addedOn: null,
    updatedOn: null
  });
  const [wizardPage, setWizardPage] = useState(1);
  const [uploadingPercent, setUploadingPercent] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [errorInput, setErrorInput] = useState(null);

  const uid = () => {
    let template_uuid = uuidv4();
    setTemID(template_uuid);
  }

  const handleNext = (event) => {
    event.preventDefault();

    if (data && data.category) {
      setWizardPage(wizardPage + 1);
    } else {
      setErrorInput({ ...errorInput, category: 'This field is required.' });
    }
  }

  const handleBack = (event) => {
    event.preventDefault();

    setWizardPage(wizardPage - 1);
  }

  useEffect(() => {
    if (updateData && updateData) {
      let tempUpdateData = { ...updateData };
      setTemID(tempUpdateData.id);
      delete tempUpdateData.id;
      setData({ ...tempUpdateData });
    } else {
      uid();
      setData({
        ...data,
        addedOn: new Date(),
        updatedOn: new Date()
      })
    }
  }, []);

  const handleUploadImage = (id, imageObj, callback) => {
    const storageRef = ref(storage, `/FestiveTemplates/${id}/${imageObj.name}`);

    const uploadTask = uploadBytesResumable(storageRef, imageObj);

    uploadTask.on("state_changed", (snapshot) => {
      const percent = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      setUploadingPercent(percent);
    }, (err) => console.log('Template Image Uploading [Error] :', err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
          callback(downloadUrl);
        });
      });
  }

  const fireData = async (id, templateUrl, templateStyles, category, isActive, type, price, addedOn, updatedOn) => {
    let docData = { templateUrl, templateStyles, category, isActive, type, price, addedOn, updatedOn };
    try {
      await setDoc(doc(db, "FestiveTemplates", id), docData).then(() => {
        setIsLoading(false);
        handleResponse('success', null);
      });
    } catch (error) {
      console.log('Adding Template Data [Error] : ', error);
      setIsLoading(false);
      handleResponse(null, 'error');
    }
  }

  const fireUpdateData = async (id, templateUrl, templateStyles, category, isActive, type, price) => {
    let docUpdateData = { id, templateUrl, templateStyles, category, isActive, type, price, updatedOn: new Date() };

    try {
      await updateDoc(doc(db, "FestiveTemplates", id), docUpdateData).then(() => {
        setIsLoading(false);
        handleResponse('success', null);
      });
    } catch (error) {
      console.log('Updating Template Data [Error] : ', error);
      setIsLoading(false);
      handleResponse(null, 'error');
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const { templateUrl, templateStyles, category, isActive, type, price, addedOn, updatedOn } = data;
    if (templateUrl && category) {
      if (imgRef.current && imgRef.current.naturalWidth !== parseInt(templateStyles.width) && imgRef.current.naturalHeight !== parseInt(templateStyles.height)) {
        setErrorInput({ ...errorInput, templateUrl: 'Image must be ' + templateStyles.width + ' & ' + templateStyles.height + ' (W & H in px).' });
      } else {
        setIsUploading(true);
        handleUploadImage(temID, data.templateUrl, (url) => {
          const tempUrl_template = url;
          setIsUploading(false);
          setIsLoading(true);
          fireData(temID, tempUrl_template, templateStyles, category, isActive, type, price, addedOn, updatedOn);
        });
      }
    } else {
      if (data && !data.templateUrl) {
        setErrorInput({ ...errorInput, templateUrl: 'Please upload the template image.' })
      }
    }
  }

  function handleUpdate(event) {
    event.preventDefault();

    const { templateUrl, templateStyles, category, isActive, type, price } = data;
    if (templateUrl && category) {
      if (imgRef.current && imgRef.current.naturalWidth !== parseInt(templateStyles.width) && imgRef.current.naturalHeight !== parseInt(templateStyles.height)) {
        setErrorInput({ ...errorInput, templateUrl: 'Image must be ' + templateStyles.width + ' & ' + templateStyles.height + ' (W & H in px).' });
      } else {
        setIsUploading(true);
        if (templateUrl !== updateData.templateUrl) {
          handleUploadImage(temID, data.templateUrl, (url) => {
            const tempUrl_template = url;
            setIsUploading(false);
            setIsLoading(true);
            fireUpdateData(temID, tempUrl_template, templateStyles, category, isActive, type, price);
          });
        }
      }
    } else {
      if (data && !data.templateUrl) {
        setErrorInput({ ...errorInput, templateUrl: 'Please upload the template image.' })
      }
    }
  }

  function handleDelete(id) {
    setIsDeleting(true);
    const fileTemplateUrl = updateData && updateData.templateUrl;
    const fileTemplateRef = ref(storage, fileTemplateUrl);
    deleteObject(fileTemplateRef).then(() => {
      const docRef = doc(db, "Templates", id);
      deleteDoc(docRef)
        .then(() => {
          setIsDeleting(false);
          handleResponse('success', null, 'Deleted Successfully!');
        }).catch((error) => {
          console.log('Deleting [Error]: ', error);
          handleResponse(null, 'error');
        });
    }).catch((error) => {
      console.log('Deleting [Error]: ', error);
      handleResponse(null, 'error');
    });
  }

  if (isUploading) {
    return (
      <div className='container'>
        <div className='row justify-content-center my-5'>
          <ProgressBar
            animated
            now={uploadingPercent}
            label={`${uploadingPercent}%`}
            style={{ padding: 0, width: '50%' }}
          />
          <h6 className='text-center text-uppercase text-secondary mt-3'><small>Uploading template</small></h6>
        </div>
      </div>
    )
  } else if (isLoading) {
    return (
      <div className='container'>
        <div className='row justify-content-center my-5'>
          <ClipLoader size={56} color="var(--bs-primary)" />
          <h6 className='text-center text-uppercase text-secondary mt-3'><small>{updateData && updateData ? 'Updating template' : 'Saving template'}</small></h6>
        </div>
      </div>
    )
  } else if (isDeleting) {
    return (
      <div className='container'>
        <div className='row justify-content-center my-5'>
          <ClipLoader size={56} color="var(--bs-danger)" />
          <h6 className='text-center text-uppercase text-secondary mt-3'><small>Deleting</small></h6>
        </div>
      </div>
    )
  } else {
    return (
      <div className='container'>
        {
          wizardPage === 1 ?
            <Form onSubmit={handleNext}>
              <div className='row'>
                <div className='col-lg-12 col-12'>
                  <Form.Group className="mb-3" controlId="category">
                    <Form.Label>Category *</Form.Label>
                    <Form.Select
                      onChange={(e) => {
                        const dimObj = templateDimensions && templateDimensions.find(c => c.name === e.target.value);
                        setData({
                          ...data,
                          category: e.target.value,
                          templateStyles: {
                            width: dimObj ? dimObj.width : '',
                            height: dimObj ? dimObj.height : ''
                          }
                        });
                        setErrorInput({ ...errorInput, category: '' });
                      }}
                      value={data.category}
                      isInvalid={errorInput && errorInput.category}
                      style={{ textTransform: 'capitalize' }}
                    >
                      <option value="">Select Category</option>
                      {
                        templateDimensions && templateDimensions.map((item, index) => {
                          return (
                            <option key={index} value={item.name}>{item.name}</option>
                          )
                        })
                      }
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errorInput && errorInput.category}</Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className='col-lg-12 col-12'>
                  <Form.Group className="mb-3" controlId="isActive">
                    <Form.Label>Status *</Form.Label>
                    <Form.Select
                      onChange={(e) => {
                        setData({
                          ...data,
                          isActive: e.target.value,
                        });
                        setErrorInput({ ...errorInput, isActive: '' })
                      }}
                      value={data.isActive}
                      isInvalid={errorInput && errorInput.isActive}
                    >
                      <option value="true">Active</option>
                      <option value="false">In Active</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errorInput && errorInput.isActive}</Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className='col-lg-12 col-12'>
                  <Form.Group className="mb-3" controlId="type">
                    <Form.Label>Premium *</Form.Label>
                    <Form.Select
                      onChange={(e) => {
                        setData({
                          ...data,
                          type: e.target.value,
                        });
                        setErrorInput({ ...errorInput, type: '' })
                      }}
                      value={data.type}
                      isInvalid={errorInput && errorInput.type}
                    >
                      <option value="freebie">Freebie</option>
                      <option value="premium">Premium</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errorInput && errorInput.type}</Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className='col-lg-12 col-12'>
                  <Form.Group className="mb-3" controlId="price">
                    <Form.Label>Price *</Form.Label>
                    <Form.Control
                      disabled={data.type === 'freebie' ? true : false}
                      type="number"
                      placeholder="Enter price"
                      onChange={(e) => {
                        setData({
                          ...data,
                          price: e.target.value,
                        });
                        setErrorInput({ ...errorInput, price: '' })
                      }}
                      className={errorInput && errorInput.price ? 'border-danger' : null}
                      value={data.price}
                      isInvalid={errorInput && errorInput.price}
                    />
                    <Form.Control.Feedback type="invalid">{errorInput && errorInput.price}</Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className='row justify-content-between mt-4'>
                <div className='col-lg-4 col-md-4 col-12'>
                  {
                    updateData && updateData ?
                      <Button
                        type="button"
                        variant="outline-danger"
                        className='w-100'
                        onClick={() => setShowModal(true)}
                      >
                        Delete
                      </Button>
                      :
                      null
                  }
                </div>
                <div className='col-lg-4 col-md-4 col-12'>
                  <Button variant="primary" className='w-100' type="submit">
                    Next
                  </Button>
                </div>
              </div>
            </Form>
            :
            <Form onSubmit={updateData && updateData ? handleUpdate : handleSubmit}>
              <div className='row'>
                <div className='col-lg-12 col-12'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <span>Template Image *</span>
                    <span className='text-danger'><small>{errorInput && errorInput.templateUrl}</small></span>
                  </div>
                  <div className='image-placeholder mt-2 d-flex align-items-center justify-content-center'>
                    <Form.Group controlId="templateImage">
                      <Form.Control
                        type="file"
                        accept="image/*"
                        onChange={(event) => {
                          setData({
                            ...data,
                            templateUrl: event.target.files[0]
                          });
                          setErrorInput({ ...errorInput, templateUrl: '' });
                        }}
                        hidden
                      />
                    </Form.Group>
                    {
                      updateData && data && data.templateUrl && data.templateUrl.includes('http') ?
                        <Image
                          ref={imgRef}
                          src={data && data.templateUrl}
                          fluid
                          rounded
                          className='h-100 w-100 p-3 object-fit-contain'
                          onClick={() => document.getElementById('templateImage').click()}
                        />
                        :
                        <Image
                          ref={imgRef}
                          src={data && data.templateUrl ? URL.createObjectURL(data.templateUrl) : placeholderImage}
                          fluid
                          rounded
                          className='h-100 w-100 p-3 object-fit-contain'
                          onClick={() => document.getElementById('templateImage').click()}
                        />
                    }
                  </div>
                </div>
              </div>
              <div className='row justify-content-between mt-4'>
                <div className='col-lg-4 col-md-4 col-12'>
                  <Button variant="outline-dark" className='w-100' type="button" onClick={handleBack}>
                    Back
                  </Button>
                </div>
                <div className='col-lg-4 col-md-4 col-12'>
                  {
                    updateData ?
                      <Button variant="primary" className='w-100' type="submit">
                        Update
                      </Button>
                      :
                      <Button variant="primary" className='w-100' type="submit">
                        Save
                      </Button>
                  }
                </div>
              </div>
            </Form>
        }

        {/* DELETE MODAL */}

        <Modal
          size="md"
          backdrop="static"
          keyboard={false}
          show={showModal}
          onHide={() => setShowModal(false)}
        >
          <Modal.Body>
            <div className='w-100 text-center mt-4'>
              <IoTrashOutline color={`var(--bs-danger)`} size={56} />
            </div>
            <h4 className='text-center mt-2'>Do you want to <span className='text-danger'>delete</span> ?</h4>
            <br />
            <div className='row justify-content-center mb-3'>
              <div className='col-md-4 col-12'>
                <Button variant="dark" className='w-100' onClick={() => setShowModal(false)}>
                  No
                </Button>
              </div>
              <div className='col-md-4 col-12'>
                <Button variant="danger" className='w-100' onClick={() => handleDelete(temID)}>
                  Yes
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default FestiveForm;
