import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Badge } from 'react-bootstrap';
import { query, collection, onSnapshot, getDocs, where, orderBy } from "firebase/firestore";
import { db } from '../../../firebase';
import placeholderImage from '../../../assets/placeholder/placeholder-image.png';
import { CiImageOff } from "react-icons/ci";

function Stories({ updateTemplate }) {

  const [parties, setParties] = useState([]);
  const [data, setData] = useState([]);

  async function getAllPartyData() {
    const q = query(collection(db, "Parties"), orderBy("name"));

    const querySnapshot = await getDocs(q);
    const tempDoc = [];
    querySnapshot.forEach((doc) => {
      tempDoc.push({ id: doc.id, ...doc.data() });
    });

    setParties(tempDoc);
  }

  async function getTemplatesData() {
    const q = query(collection(db, "Templates"), where('category', '==', 'stories'), orderBy("addedOn", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const tempData = [];
      querySnapshot.forEach((doc) => {
        tempData.push({ id: doc.id, ...doc.data() });
      });
      setData(tempData);
    }, (error) => {
      console.log(error.message);
    });

    return () => unsubscribe;
  }

  useEffect(() => {
    getAllPartyData();
    getTemplatesData();
  }, []);

  if (data && data.length > 0) {
    return (
      <Row>
        {
          data && data.map((item, index) => {

            let partyObject = null;

            if (parties) {
              partyObject = parties.find((party) => {
                return party.id == item.party;
              });
            }

            let date_addedOn = item.addedOn.toDate().toDateString();
            let date_updatedOn = item.updatedOn.toDate().toDateString();

            return (
              <Col key={index} md={3} className='mt-3'>
                <Card className='card-hover' onClick={() => updateTemplate(item)}>
                  {
                    item.sampleUrl || item.templateUrl ?
                      <Card.Img variant="top" src={item.sampleUrl ? item.sampleUrl : item.templateUrl} />
                      :
                      <Card.Img variant="top" src={placeholderImage} />
                  }
                  <Card.Body>
                    <div className='d-flex justify-content-between'>
                      <Badge bg="light" className='fw-normal text-secondary shadow-none'>{date_addedOn ? date_addedOn : '--/--/--'}</Badge>
                      <Badge bg="success" className='fw-normal text-light shadow-sm'>{item.isActive ? 'Active' : 'Inactive'}</Badge>
                    </div>
                    <div className='mt-3 d-flex justify-content-between'>
                      <span><small>Party</small></span>
                      <span className='fw-bold'><small>{partyObject && partyObject.initial}</small></span>
                    </div>
                    <div className='d-flex justify-content-between'>
                      <span><small>Type</small></span>
                      <span className={item.type == 'freebie' ? 'text-success fw-bold text-capitalize' : 'text-danger fw-bold text-capitalize'}><small>{item.type}</small></span>
                    </div>
                    <div className='d-flex justify-content-between'>
                      <span><small>Price</small></span>
                      {
                        item.type == 'freebie' ?
                          <span className='fw-normal'><small>NA</small></span>
                          :
                          <span className='fw-bold'><small>₹ {item.price ? item.price : 0}</small></span>
                      }
                    </div>
                    <div className='d-flex justify-content-between'>
                      <span><small>Uploaded on</small></span>
                      <span className='fw-bold'><small>{date_updatedOn ? date_updatedOn : '--/--/--'}</small></span>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            )
          })
        }
      </Row >
    );
  } else {
    return (
      <div className='d-flex align-items-center justify-content-center'>
        <div className='py-5 text-center'>
          <CiImageOff size={48} color='var(--muted)' />
          <h5 className='text-muted mt-3'>No template found!</h5>
        </div>
      </div>
    )
  }
}

export default Stories;
