import React, { useState, useEffect } from 'react';
import { Button, Form, Image, Modal, ProgressBar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ClipLoader } from 'react-spinners';
import { doc, query, collection, setDoc, getDocs, updateDoc, orderBy, deleteDoc } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytesResumable, deleteObject } from "firebase/storage";
import { db, storage } from '../../../firebase';
import './index.css';
import placeholderImage from '../../../assets/placeholder/placeholder-image.png';
import { IoTrashOutline } from "react-icons/io5";

function ItemForm({ updateData, handleResponse }) {

  let navigate = useNavigate();

  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [parties, setParties] = useState(null);
  const [temID, setTemID] = useState('');
  const [data, setData] = useState({
    party: '',
    isActive: true,
    type: 'freebie',
    url: '',
    addedOn: null,
    updatedOn: null
  });
  const [wizardPage, setWizardPage] = useState(1);
  const [uploadingPercent, setUploadingPercent] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [errorInput, setErrorInput] = useState(null);

  const uid = () => {
    let new_uuid = uuidv4();
    setTemID(new_uuid);
  }

  async function getParties() {
    const q = query(collection(db, "Parties"), orderBy("name"));

    const querySnapshot = await getDocs(q);
    const tempDoc = [];
    querySnapshot.forEach((doc) => {
      tempDoc.push({ id: doc.id, ...doc.data() });
    });

    setParties(tempDoc);
  }

  const handleNext = (event) => {
    event.preventDefault();

    if (data && data.url) {
      setWizardPage(wizardPage + 1);
    } else {
      setErrorInput({ ...errorInput, url: 'Please upload the symbol image.' })
    }
  }

  const handleBack = (event) => {
    event.preventDefault();

    setWizardPage(wizardPage - 1);
  }

  useEffect(() => {
    if (updateData && updateData) {
      let tempUpdateData = { ...updateData };
      setTemID(tempUpdateData.id);
      delete tempUpdateData.id;
      setData({ ...tempUpdateData });
    } else {
      uid();
      setData({
        ...data,
        addedOn: new Date(),
        updatedOn: new Date()
      })
    }
    getParties();
  }, []);

  const handleUploadImage = (id, imageObj, callback) => {
    const storageRef = ref(storage, `/Frames/${id}/${imageObj.name}`);

    const uploadTask = uploadBytesResumable(storageRef, imageObj);

    uploadTask.on("state_changed", (snapshot) => {
      const percent = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      );
      setUploadingPercent(percent);
    }, (err) => console.log('Image Uploading [Error] :', err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
          callback(downloadUrl);
        });
      });
  }

  const fireData = async (id, url, party, isActive, type, addedOn, updatedOn) => {
    let docData = { url, party, isActive, type, addedOn, updatedOn };
    try {
      await setDoc(doc(db, "Frames", id), docData).then(() => {
        setIsLoading(false);
        handleResponse('success', null);
      });
    } catch (error) {
      console.log('Adding Data [Error] : ', error);
      setIsLoading(false);
      handleResponse(null, 'error');
    }
  }

  const fireUpdateData = async (id, url, party, isActive, type) => {
    let docUpdateData = { id, url, party, isActive, type, updatedOn: new Date() };
    delete docUpdateData.id;
    try {
      await updateDoc(doc(db, "Frames", id), docUpdateData).then(() => {
        setIsLoading(false);
        handleResponse('success', null);
      });
    } catch (error) {
      console.log('Updating Data [Error] : ', error);
      setIsLoading(false);
      handleResponse(null, 'error');
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const { url, party, isActive, type, addedOn, updatedOn } = data;
    if (url && party) {
      setIsUploading(true);
      handleUploadImage(temID, data.url, (url) => {
        const tempUrl = url;
        setIsUploading(false);
        setIsLoading(true);
        fireData(temID, tempUrl, party, isActive, type, addedOn, updatedOn);
      });
    } else {
      if (data && !data.party) {
        setErrorInput({ ...errorInput, party: 'This field is required.' });
      }
    }
  }

  function handleUpdate(event) {
    event.preventDefault();

    const { url, party, isActive, type, addedOn, updatedOn } = data;
    if (url && party) {
      setIsUploading(true);
      if (url != updateData.url) {
        handleUploadImage(temID, data.url, (url) => {
          const tempUrl = url;
          setIsUploading(false);
          setIsLoading(true);
          fireUpdateData(temID, tempUrl, party, isActive, type);
        });
      } else {
        setIsUploading(false);
        setIsLoading(true);
        fireUpdateData(temID, url, party, isActive, type);
      }
    } else {
      if (data && !data.party) {
        setErrorInput({ ...errorInput, party: 'This field is required.' });
      }
    }
  }

  function handleDelete(id) {
    setIsDeleting(true);
    const fileUrl = updateData && updateData.url;
    const fileRef = ref(storage, fileUrl);
    deleteObject(fileRef).then(() => {
      const docRef = doc(db, "Frames", id);
      deleteDoc(docRef)
        .then(() => {
          setIsDeleting(false);
          handleResponse('success', null, 'Deleted Successfully!');
        }).catch((error) => {
          console.log('Deleting [Error]: ', error);
          handleResponse(null, 'error');
        });
    }).catch((error) => {
      console.log('Deleting [Error]: ', error);
      handleResponse(null, 'error');
    });
  }

  const isValidUrl = urlString => {
    var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
    return !!urlPattern.test(urlString);
  }

  if (isUploading) {
    return (
      <div className='container'>
        <div className='row justify-content-center my-5'>
          <ProgressBar
            animated
            now={uploadingPercent}
            label={`${uploadingPercent}%`}
            style={{ padding: 0, width: '50%' }}
          />
          <h6 className='text-center text-uppercase text-secondary mt-3'><small>Uploading</small></h6>
        </div>
      </div>
    )
  } else if (isLoading) {
    return (
      <div className='container'>
        <div className='row justify-content-center my-5'>
          <ClipLoader size={56} color="var(--bs-primary)" />
          <h6 className='text-center text-uppercase text-secondary mt-3'><small>{updateData && updateData ? 'Updating' : 'Saving'}</small></h6>
        </div>
      </div>
    )
  } else if (isDeleting) {
    return (
      <div className='container'>
        <div className='row justify-content-center my-5'>
          <ClipLoader size={56} color="var(--bs-danger)" />
          <h6 className='text-center text-uppercase text-secondary mt-3'><small>Deleting</small></h6>
        </div>
      </div>
    )
  } else {
    return (
      <div className='container'>
        {
          wizardPage == 1 ?
            <Form onSubmit={handleNext}>
              <div className='row'>
                <div className='col-12'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <span>Frame Image *</span>
                    <span className='text-danger'><small>{errorInput && errorInput.url}</small></span>
                  </div>
                  <div className='image-placeholder mt-2 d-flex align-items-center justify-content-center'>
                    <Form.Group controlId="imageUrl">
                      <Form.Control
                        type="file"
                        accept="image/*"
                        onChange={(event) => {
                          setData({
                            ...data,
                            url: event.target.files[0]
                          });
                          setErrorInput({ ...errorInput, url: '' });
                        }}
                        hidden
                      />
                    </Form.Group>
                    {
                      isValidUrl(data.url) ?
                        <Image
                          src={data && data.url}
                          fluid
                          rounded
                          className='h-100 w-100 p-3 object-fit-contain'
                          onClick={() => document.getElementById('imageUrl').click()}
                        />
                        :
                        <Image
                          src={data && data.url ? URL.createObjectURL(data.url) : placeholderImage}
                          fluid
                          rounded
                          className='h-100 w-100 p-3 object-fit-contain'
                          onClick={() => document.getElementById('imageUrl').click()}
                        />
                    }
                  </div>
                </div>
              </div>
              <div className='row justify-content-between mt-4'>
                <div className='col-lg-3 col-md-4 col-12'>
                  {
                    updateData && updateData ?
                      <Button
                        type="button"
                        variant="outline-danger"
                        className='w-100'
                        onClick={() => setShowModal(true)}
                      >
                        Delete
                      </Button>
                      :
                      null
                  }
                  {/* DELETE MODAL */}

                  <Modal
                    size="md"
                    backdrop="static"
                    keyboard={false}
                    show={showModal}
                    onHide={() => setShowModal(false)}
                  >
                    <Modal.Body>
                      <div className='w-100 text-center mt-4'>
                        <IoTrashOutline color={`var(--bs-danger)`} size={56} />
                      </div>
                      <h4 className='text-center mt-2'>Do you want to <span className='text-danger'>delete</span> ?</h4>
                      <br />
                      <div className='row justify-content-center mb-3'>
                        <div className='col-md-4 col-12'>
                          <Button variant="dark" className='w-100' onClick={() => setShowModal(false)}>
                            No
                          </Button>
                        </div>
                        <div className='col-md-4 col-12'>
                          <Button variant="danger" className='w-100' onClick={() => handleDelete(temID)}>
                            Yes
                          </Button>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
                <div className='col-lg-3 col-md-4 col-12'>
                  <Button variant="primary" className='w-100' type="submit">
                    Next
                  </Button>
                </div>
              </div>
            </Form>
            :
            <Form onSubmit={updateData && updateData ? handleUpdate : handleSubmit}>
              <div className='row'>
                <div className='col-12'>
                  <Form.Group className="mb-3" controlId="party">
                    <Form.Label>Party *</Form.Label>
                    <Form.Select
                      onChange={(e) => {
                        setData({
                          ...data,
                          party: e.target.value,
                        });
                        setErrorInput({ ...errorInput, party: '' })
                      }}
                      value={data.party}
                      isInvalid={errorInput && errorInput.party}
                    >
                      <option value="">Select Party</option>
                      {
                        parties && parties.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>{item.initial} ({item.name})</option>
                          )
                        })
                      }
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errorInput && errorInput.party}</Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className='col-12'>
                  <Form.Group className="mb-3" controlId="isActive">
                    <Form.Label>Status *</Form.Label>
                    <Form.Select
                      onChange={(e) => {
                        setData({
                          ...data,
                          isActive: e.target.value,
                        });
                        setErrorInput({ ...errorInput, isActive: '' })
                      }}
                      value={data.isActive}
                      isInvalid={errorInput && errorInput.isActive}
                    >
                      <option value="true">Active</option>
                      <option value="false">In Active</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errorInput && errorInput.isActive}</Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className='col-12'>
                  <Form.Group className="mb-3" controlId="type">
                    <Form.Label>Premium *</Form.Label>
                    <Form.Select
                      onChange={(e) => {
                        setData({
                          ...data,
                          type: e.target.value,
                        });
                        setErrorInput({ ...errorInput, type: '' })
                      }}
                      value={data.type}
                      isInvalid={errorInput && errorInput.type}
                    >
                      <option value="freebie">Freebie</option>
                      <option value="premium">Premium</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errorInput && errorInput.type}</Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className='row justify-content-between mt-4'>
                <div className='col-lg-4 col-md-4 col-12'>
                  <Button variant="outline-dark" className='w-100' type="button" onClick={handleBack}>
                    Back
                  </Button>
                </div>
                <div className='col-lg-4 col-md-4 col-12'>
                  {
                    updateData ?
                      <Button variant="primary" className='w-100' type="submit">
                        Update
                      </Button>
                      :
                      <Button variant="primary" className='w-100' type="submit">
                        Save
                      </Button>
                  }
                </div>
              </div>
            </Form>
        }
      </div>
    );
  }
}

export default ItemForm;
