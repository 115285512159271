import React from 'react';
import { IoGridOutline, IoMapOutline, IoPeopleOutline, IoBookmarksOutline, IoExtensionPuzzleOutline, IoFlagOutline } from 'react-icons/io5';
import { PiFrameCorners } from "react-icons/pi";
import { LuUngroup } from "react-icons/lu";
import { RiFireLine } from "react-icons/ri";
import { NavLink } from 'react-router-dom';
import './index.css';
import logo from '../../../assets/logo/next2estate-black.png';

function SideBar({ sidenavCollapsed, setSidenavCollapsed }) {

  const handleSidenav = () => {
    setSidenavCollapsed(!sidenavCollapsed);
  }

  return (
    <div className='sidebar'>
      <div className='sidebar-head'>
        <div className={sidenavCollapsed ? 'collapsed-side-hide' : 'sidebar-logo'}>
          <img src={logo} alt="next2estate-logo" />
          <div className='brand-name'>
            PBM Admin
          </div>
        </div>
      </div>
      <ul>
        <li>
          <NavLink to="/admin/dashboard" >
            <div className='link-icon'><IoGridOutline color={`var(--primary)`} size={19} /></div>
            <div className={sidenavCollapsed ? 'collapsed-side-hide' : 'link-label'}>Dashboard</div>
          </NavLink>
        </li>
        <li>
          <div className={sidenavCollapsed ? 'collapsed-side-hide' : 'ps-3 text-placeholder py-2 pb-2'}>
            <small>Templates</small>
          </div>
          <ul>
            <li>
              <NavLink to="/admin/political-templates">
                <div className='link-icon'><IoExtensionPuzzleOutline color={`var(--bs-orange)`} size={20} /></div>
                <div className={sidenavCollapsed ? 'collapsed-side-hide' : 'link-label'}>Political</div>
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/festive-templates">
                <div className='link-icon'><RiFireLine color={`var(--bs-orange)`} size={20} /></div>
                <div className={sidenavCollapsed ? 'collapsed-side-hide' : 'link-label'}>Festive</div>
              </NavLink>
            </li>
          </ul>
        </li>
        <li>
          <div className={sidenavCollapsed ? 'collapsed-side-hide' : 'ps-3 text-placeholder py-2 pb-2'}>
            <small>Assets</small>
          </div>
          <ul>
            <li>
              <NavLink to="/admin/frames">
                <div className='link-icon'><PiFrameCorners color={`var(--bs-orange)`} size={20} /></div>
                <div className={sidenavCollapsed ? 'collapsed-side-hide' : 'link-label'}>Frames</div>
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/symbols">
                <div className='link-icon'><IoBookmarksOutline color={`var(--bs-orange)`} size={20} /></div>
                <div className={sidenavCollapsed ? 'collapsed-side-hide' : 'link-label'}>Symbols</div>
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/flags">
                <div className='link-icon'><IoFlagOutline color={`var(--bs-info)`} size={20} /></div>
                <div className={sidenavCollapsed ? 'collapsed-side-hide' : 'link-label'}>Flags</div>
              </NavLink>
            </li>
            <li>
              <NavLink to="/admin/leaders">
                <div className='link-icon'><IoPeopleOutline color={`var(--bs-blue)`} size={20} /></div>
                <div className={sidenavCollapsed ? 'collapsed-side-hide' : 'link-label'}>Leaders</div>
              </NavLink>
            </li>
          </ul>
        </li>
        <li>
          <div className={sidenavCollapsed ? 'collapsed-side-hide' : 'ps-3 text-placeholder py-2 pb-2'}>
            <small>Settings</small>
          </div>
          <ul>
            <li>
              <NavLink to="/admin/parties">
                <div className='link-icon'><LuUngroup color={`var(--bs-orange)`} size={20} /></div>
                <div className={sidenavCollapsed ? 'collapsed-side-hide' : 'link-label'}>Parties</div>
              </NavLink>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default SideBar;
