import React, { useEffect, useState } from 'react';
import { collection, getCountFromServer } from "firebase/firestore";
import { db, storage } from '../../../firebase';

function Dashboard() {

  const [userCount, setUserCount] = useState(0);
  const [templateCount, setTemplateCount] = useState(0);

  async function getUsers() {
    const coll = collection(db, "cities");
    const snapshot = await getCountFromServer(coll);
    console.log('count: ', snapshot.data().count);
  }

  useEffect(() => {
    // getUsers();
  }, []);

  return (
    <section className='section px-3'>
      <div className='row'>
        <div className='col-lg-4 col-md-6 col-sm-12 col-12 mt-3'>
          <div className='card custom-shadow bg-white p-3 border-0'>
            <div className='d-flex align-items-center justify-content-between'>
              <h6 className='mb-0 fw-light'>
                User<br /><span className='h5'>Registered</span>
              </h6>
              <h4 className='fw-bold mb-0'>
                {userCount && userCount}
              </h4>
            </div>
          </div>
        </div>
        <div className='col-lg-4 col-md-6 col-sm-12 col-12 mt-3'>
          <div className='card custom-shadow bg-white p-3 border-0'>
            <div className='d-flex align-items-center justify-content-between'>
              <h6 className='mb-0 fw-light'>
                Total<br /><span className='h5'>Templates</span>
              </h6>
              <h4 className='fw-bold mb-0'>
                {templateCount && templateCount}
              </h4>
            </div>
          </div>
        </div>
        <div className='col-lg-4 col-md-6 col-sm-12 col-12 mt-3'>
          <div className='card custom-shadow bg-white p-3 border-0'>
            <div className='d-flex align-items-center justify-content-between'>
              <h6 className='mb-0 fw-light'>
                Total<br /><span className='h5'>Parties</span>
              </h6>
              <h4 className='fw-bold mb-0'>
                100
              </h4>
            </div>
          </div>
        </div>
        <div className='col-lg-4 col-md-6 col-sm-12 col-12 mt-3'>
          <div className='card custom-shadow bg-white p-3 border-0'>
            <div className='d-flex align-items-center justify-content-between'>
              <h6 className='mb-0 fw-light'>
                Total<br /><span className='h5'>Symbols</span>
              </h6>
              <h4 className='fw-bold mb-0'>
                100
              </h4>
            </div>
          </div>
        </div>
        <div className='col-lg-4 col-md-6 col-sm-12 col-12 mt-3'>
          <div className='card custom-shadow bg-white p-3 border-0'>
            <div className='d-flex align-items-center justify-content-between'>
              <h6 className='mb-0 fw-light'>
                Total<br /><span className='h5'>Flags</span>
              </h6>
              <h4 className='fw-bold mb-0'>
                100
              </h4>
            </div>
          </div>
        </div>
        <div className='col-lg-4 col-md-6 col-sm-12 col-12 mt-3'>
          <div className='card custom-shadow bg-white p-3 border-0'>
            <div className='d-flex align-items-center justify-content-between'>
              <h6 className='mb-0 fw-light'>
                Total<br /><span className='h5'>Leaders</span>
              </h6>
              <h4 className='fw-bold mb-0'>
                100
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col-lg-8 col-md-6 col-sm-12 col-12 mb-5'>
          <div className='card h-100 border-0'>
            <h6 className='text-uppercase p-3 mb-0'>Recent User Signup</h6>
            <hr className='m-0' />
            <div className='p-3'>
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>User Name</th>
                      <th>Party</th>
                      <th>Email Id</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>10, Jun 2023</td>
                      <td>Mohammad Rahil Siddiqui</td>
                      <td>INC</td>
                      <td>rahil.says@gmail.com</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-4 col-md-6 col-sm-12 col-12 mb-5'>
          <div className='card h-100 border-0'>
            <h6 className='text-uppercase p-3 mb-0'>Recent Template Added</h6>
            <hr className='m-0' />
            <div className='p-3'>
              <div className="table-responsive overflow-scroll">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th></th>
                      <th>Category</th>
                      <th>Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>10, Jun 2023</td>
                      <td>Image</td>
                      <td>Flyer</td>
                      <td>Freebie</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Dashboard;
