import React, { useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import { AuthContextProvider, useAuth } from './context/AuthContext';
import { ToastContainer } from 'react-toastify';

import AuthRoute from './components/routes/auth-route';
import Login from './pages/auth/login';

import ProtectedRoute from './components/routes/protected-route';

import Admin from './pages/admin';
import Dashboard from './pages/admin/dashboard';
import Templates from './pages/admin/templates';
import Symbols from './pages/admin/symbols';
import Flags from './pages/admin/flags';
import Leaders from './pages/admin/leaders';
import Festive from './pages/admin/festive';
import FrameItems from './pages/admin/frames';

function App() {

  //const { permissions } = useAuth();

  return (
    <div className='App'>
      <ToastContainer />
      <AuthContextProvider>
        <Routes>
          <Route path='/' element={<AuthRoute><Login /></AuthRoute>} />
          <Route path='/admin' element={<ProtectedRoute> <Admin /> </ProtectedRoute>}>
            <Route path='/admin/dashboard' element={<Dashboard />} />
            <Route path='/admin/political-templates' element={<Templates />} />
            <Route path='/admin/festive-templates' element={<Festive />} />
            <Route path='/admin/frames' element={<FrameItems />} />
            <Route path='/admin/symbols' element={<Symbols />} />
            <Route path='/admin/flags' element={<Flags />} />
            <Route path='/admin/leaders' element={<Leaders />} />
          </Route>
          <Route path='*' element={<div><h1>Page Not Found</h1></div>} />
        </Routes>
      </AuthContextProvider>
    </div>
  );
}

export default App;
