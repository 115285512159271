import React, { useState, useEffect } from 'react';
import './index.css';
import { IoAdd, IoChevronDown } from 'react-icons/io5';
import { Button, Tab, Nav, Row, Col, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Posts from '../../../components/festive/posts';
import Stories from '../../../components/festive/stories';
import Banners from '../../../components/festive/banners';
import Flyers from '../../../components/festive/flyers';
import Wallpapers from '../../../components/festive/wallpapers';
import FestiveForm from '../../../components/forms/festive';

function FestiveTemplates() {

  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState(null);

  function closeDataModal() {
    setData(null);
    setShowModal(false);
  }

  function handleDataResponse(success, error) {
    if (success) {
      closeDataModal();
      toast.success('Successfully Done.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      closeDataModal();
      toast.error('Something went wrong!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }

  function updateTemplate(dataItem) {
    console.log(dataItem);
    if (dataItem) {
      setData(dataItem);
      setShowModal(true);
    }
  }

  return (
    <section className='section p-3'>
      <div className='row'>
        <div className='col-12'>
          <div className='card custom-shadow bg-white p-3 border-0'>
            <div className='d-flex align-items-center justify-content-between'>
              <h4 className='mb-0'>
                Festive Templates
              </h4>
              <div>
                <Button
                  onClick={() => setShowModal(!showModal)}
                >
                  <IoAdd size={24} />
                  <span className='ms-2'>New Template</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col-12'>
          <div className='card custom-shadow bg-white p-3 ps-4 border-0'>
            <Tab.Container defaultActiveKey="posts">
              <Row>
                <Col sm={9} className='bg-light py-2 rounded'>
                  <Nav variant="pills" className="flex-row">
                    <Nav.Item>
                      <Nav.Link eventKey="posts" className='px-4'>Posts</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="stories" className='px-4'>Stories</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="banners" className='px-4'>Banners</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="flyers" className='px-4'>Flyers</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="wallpapers" className='px-4'>Wallpapers</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={3} className='d-flex align-items-center justify-content-end'>
                  <Button
                    variant='light'
                    className='shadow-none bg-white border-0 text-decoration-none'
                  >
                    <span className='ms-1 me-2'>Sort By : <b>Latest</b></span>
                    <IoChevronDown size={20} />
                  </Button>
                </Col>
                <Col sm={12}>
                  <Tab.Content className='mt-4'>
                    <Tab.Pane eventKey="posts">
                      <Posts updateTemplate={updateTemplate} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="stories">
                      <Stories updateTemplate={updateTemplate} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="banners">
                      <Banners updateTemplate={updateTemplate} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="flyers">
                      <Flyers updateTemplate={updateTemplate} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="wallpapers">
                      <Wallpapers updateTemplate={updateTemplate} />
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </div>

      {/* NEW DATA MODAL */}

      <Modal
        size="md"
        backdrop="static"
        keyboard={false}
        show={showModal}
        onHide={() => closeDataModal()}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {
              data && data ?
                'Update Template'
                :
                'Add New Template'
            }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FestiveForm
            updateData={data}
            handleResponse={handleDataResponse}
          />
        </Modal.Body>
      </Modal>

    </section>
  );
}

export default FestiveTemplates;
